import { lazy } from "react";
import memoizeOne from 'memoize-one';
import moment from "moment/moment";
import getDaysRemainingHandler from 'utils/getDaysRemaining';
import { getPercentage as getPercentageHandler } from 'utils/helpers';

const DashboardContent = lazy(() => import('App/components/Dashboard/components/DashboardContent'));
const WeeklyStatus = lazy(() => import('pages/WeeklyStatus/WeeklyStatus'));
const WeeklySpecificStatus = lazy(() => import('pages/WeeklySpecificStatus'));
const PulsePoint = lazy(() => import('pages/PulsePoint'));
const PulsePointSpecificUser = lazy(() => import('pages/PulsePoint/PulsePointSpecificUser'));
const Goal = lazy(() => import("pages/Goal"));
const Survey = lazy(() => import("pages/Survey"));
const CheckIn = lazy(() => import("pages/CheckIn"));
const User = lazy(() => import("pages/User"));
const Review = lazy(() => import("pages/Review"));
const SurveyPage = lazy(() => import("pages/Surveys"));
const AuditTrail = lazy(() => import('pages/AuditTrail/AuditTrail.js'));
const TeamsAndPeople = lazy(() => import('pages/TeamsAndPeople/TeamsAndPeople.js'));
const FormTemplates = lazy(() => import('pages/FormTemplates/FormTemplates.js'));
const CheckIns = lazy(() => import('pages/CheckIns/CheckIns.js'));
const Goals = lazy(() => import('pages/Goals/Goals.js'));
const CompanySettings = lazy(() => import('pages/CompanySettings/CompanySettings.js'));
const Reviews = lazy(() => import('pages/Reviews'));
const MyProfile = lazy(() => import('pages/MyProfile'));
const Feeds = lazy(() => import('pages/Feeds'));
const MyGamification = lazy(() => import('pages/MyGamification'));

function getRoutes(generalSettings, user) {
    return [
        {path: '/', Component: DashboardContent, type: 'public', isAvailable: true},
        {path: '/dashboard', Component: DashboardContent, type: 'public', isAvailable: true},
        {path: '/weekly_status', Component: WeeklyStatus, type: 'public', pricingId: 'weekly_status', isAvailable: true},
        {path: '/weekly_status/:id', Component: WeeklySpecificStatus, type: 'public', pricingId: 'weekly_status', isAvailable: true},
        {path: '/pulse_points', Component: PulsePoint, type: 'public', pricingId: 'pulse_point', isAvailable: !!generalSettings?.pulse_point},
        {path: '/pulse_points/:id', Component: PulsePointSpecificUser, type: 'public', pricingId: 'pulse_point', isAvailable: !!generalSettings?.pulse_point},
        {path: '/goals', Component: Goals, type: 'public', pricingId: 'goals', isAvailable: true},
        {path: '/goals/:id', Component: Goal, type: 'public', pricingId: 'goals', isAvailable: true},
        {path: '/reviews', Component: Reviews, type: 'public', pricingId: 'reviews', isAvailable: true},
        {path: '/reviews/:state/:id', Component: Review, type: 'public', pricingId: 'reviews', isAvailable: true},
        {path: '/check-ins', Component: CheckIns, type: 'public', pricingId: 'checkins', isAvailable: true},
        {path: '/check-ins/:id', Component: CheckIn, type: 'public', pricingId: 'checkins', isAvailable: true},
        {path: '/surveys', Component: SurveyPage, type: 'public', pricingId: 'surveys', isAvailable: true},
        {path: '/surveys/:id', Component: Survey, type: 'public', pricingId: 'surveys', isAvailable: true},
        {path: '/teams-people', Component: TeamsAndPeople, type: 'private', isAvailable: true},
        {path: '/teams-people/user/:id', Component: User, type: 'private', isAvailable: true},
        {path: '/form_templates', Component: FormTemplates, type: 'private', isAvailable: true},
        {path: '/audit_trail', Component: AuditTrail, type: 'protected', isAvailable: true},
        {path: '/settings', Component: CompanySettings, type: 'protected', isAvailable: true},
        {path: '/my_profile', Component: MyProfile, type: 'public', isAvailable: !user?.cms_user},
        {path: '/feeds', Component: Feeds, type: 'public', isAvailable: true},
        {path: '/my_gamification', Component: MyGamification, type: 'public', isAvailable: !!generalSettings?.gamified},
    ]
}

const getPathHandler = (pathname) => {
    return pathname === process.env.REACT_APP_URL_PREFIX || pathname === `${process.env.REACT_APP_URL_PREFIX}/`
}

const defaultPeriodHandler = (week) => {
    if (week) {
        const monday = moment(week.monday).format('D MMM');
        const friday = moment(week.friday).format('D MMM');

        return `${monday} - ${friday}`;
    }
}

const periodHandler = (dateInterval) => {
    if (dateInterval.start || dateInterval.end) {
        const start = moment(dateInterval.start).parseZone().format('D MMM');
        const end = moment(dateInterval.end).parseZone().format('D MMM');

        return `${start} - ${end}`;
    }
}

const todoListTabsHandler = (initialWeeklyTodoList) => [
    { name: '2', content: initialWeeklyTodoList?.count_for_next_2_days },
    { name: '7', content: initialWeeklyTodoList?.count_for_next_7_days },
    { name: '30', content: initialWeeklyTodoList?.count_for_next_30_days },
];

const getMenuItemHandler = (item) => item.toLowerCase().split(/ & |\s/).join('_');

export const getGamificationRoutes = memoizeOne(getRoutes);
export const getPath = memoizeOne(getPathHandler);
export const getDefaultPeriod = memoizeOne(defaultPeriodHandler);
export const getPeriod = memoizeOne(periodHandler);
export const getTodoListTabs = memoizeOne(todoListTabsHandler);
export const getDaysRemaining = memoizeOne(getDaysRemainingHandler);
export const getPercentage = memoizeOne(getPercentageHandler);
export const getMenuItemParam = memoizeOne(getMenuItemHandler);