import React from 'react';
import "./style/error_ui.scss";

const ErrorUI = () => {
    return (
        <section className="error_page">
            <h2>OOP'S something went wrong</h2>
        </section>
    )
}

export default React.memo(ErrorUI);