import {useEffect} from "react";

const UseTaskManager = (tasks) => {
    useEffect(() => {

        const taskWorker = new Worker('workers/task-manager.js', { type: "module" });
        taskWorker.onmessage = (index) => {
            tasks[index].fn();
        }
        taskWorker.postMessage(tasks.map(e => e.cronDate))
    }, []);
}
export default UseTaskManager;