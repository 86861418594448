import { useEffect } from "react";

const usePersist = (key, value, asBoolean = false) => {
    useEffect(() => {
        if (typeof value === 'undefined') {
            return;
        }

        if (asBoolean) {
            return localStorage.setItem(key, value ? 'true' : '');
        }

        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);
};

export default usePersist;
