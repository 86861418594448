import React from 'react';
import {createRoot} from "react-dom/client";
import App from './App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './assets/styles/global.scss';
import 'antd/dist/antd.min.css';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(<App/>);

serviceWorkerRegistration.register();

// node - v17.8.0  npm - v8.5.5
