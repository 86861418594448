import {useEffect} from 'react';

export default function useOnClickOutside(ref, handler, whiteList) {
    useEffect(
        () => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                if (whiteList && whiteList?.length) {
                    for (const item of whiteList) {
                        const element = document.querySelector(item);

                        if (element && element.contains(event.target)) {
                            return;
                        }
                    }
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                if (document) {
                    document.removeEventListener('mousedown', listener);
                    document.removeEventListener('touchstart', listener);
                }
            };
        },
        [ref, handler, whiteList]
    );
}
