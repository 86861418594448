import React, {Component} from 'react';
import ErrorUI from "./ErrorUI/ErrorUI";

class ErrorBoundary extends Component {
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <ErrorUI/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;