export const dateFormats = [
    {
        value: 'm/d/Y',
        label: 'MM/DD/YYYY'
    },
    {
        value: 'd/m/Y',
        label: 'DD/MM/YYYY'
    },
    {
        value: 'Y/m/d',
        label: 'YYYY/MM/DD'
    },
];

