import {modulesSettings} from "./components/Modules/components/ModulesSettings/moduleSettings.config";

export const tabletMediaString = '(min-width: 768px) and (max-width: 992px)';
export const mobileMediaString = '(max-width: 767px)';
export const desktopMediaString = '(min-width: 993px)';

export const deviceTypes = {
    desktop: 'desktop',
    tablet: 'tablet',
    mobile: 'mobile'
};

export const reviews = 'reviews';

export const generateModulesBreadcrumb = (module, index) => {
    const config = modulesSettings[module]?.nestedMenu[index];
    return [module, config];
};

export const tabNames = {
    general: 'General',
    modules: 'Modules'
};