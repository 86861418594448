/**
 * @param {number} days Days remaining
 * @param {number} hours Hours remaining
 * @return {string}
*/
const getDaysRemaining = (days, hours) => {
    if (days) {
        if (days === 1) {
            return `${days} day remaining`;
        }

        return `${days} days remaining`;
    }

    if (!hours || hours === 1) {
        return '1 hour remaining';
    }

    return `${hours} hours remaining`;
};

export default getDaysRemaining;
