/**
 * Uses try catch to parse JSON
 * @param {string} string
 * @return {any | null}
*/
const parseJsonSafely = (string) => {
    if (!string) {
        return null;
    }

    try {
        return JSON.parse(string);
    } catch (e) {
        return null;
    }
};

export default parseJsonSafely;