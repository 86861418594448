import {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";

// Utils
import {
    generalCurrentSettings,
    companyLogo,
    companyLogoIsDark,
    gamificationDataCount,
    isFreeTrial,
    isYourCompanyBlocked,
} from 'store';

// Models
import {generalSetting} from "models/generalSetting";
import {myGamification} from "models/myGamification";

// Assets
import eleapLogo from '../assets/images/eLeaP_logo.svg';


const useCompanyLogo = () => {
    const [generalSettings, setGeneralSettings] = useRecoilState(generalCurrentSettings);
    const setGamificationCount = useSetRecoilState(gamificationDataCount);
    const [logo, setLogo] = useRecoilState(companyLogo);
    const [isLogoDark, setIsLogoDark] = useRecoilState(companyLogoIsDark);
    const isFreeTrialExpired = useRecoilValue(isFreeTrial);
    const isAccountBlocked = useRecoilValue(isYourCompanyBlocked);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!Object.keys(generalSettings).length) {
            (async () => {
                try {
                    const {data} = await generalSetting.getGeneralSetting();

                    if (data?.success) {
                        setGeneralSettings(data.general_setting);
                    }
                } catch (e) {
                    console.log(e);
                }
            })();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ((isFreeTrialExpired === false) && (isAccountBlocked === false)) {
            (async () => {
                try {
                    const {data} = await myGamification.getUserGamificationCount();

                    if (data?.success) {
                        setGamificationCount(data);
                    }
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [isFreeTrialExpired, isAccountBlocked]);


    useEffect(() => {
        if (!Object.keys(generalSettings).length) {
            return;
        }

        setIsLogoDark(Number(generalSettings.file_is_dark));

        const src = generalSettings.logo_path || eleapLogo;

        const img = new Image();
        img.src = src;

        img.onload = () => {
            setLoaded(true);
            setLogo(src);
        };
        img.onerror = () => {
            setLoaded(true);
            setLogo(eleapLogo);
        };
        // eslint-disable-next-line
    }, [generalSettings]);

    return {
        logo: loaded ? logo : '',
        generalSettings,
        loaded,
        setLogo,
        isDefaultLogo: logo === eleapLogo,
        isLogoDark,
        setIsLogoDark,
    };
};

export default useCompanyLogo;
