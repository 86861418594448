import {stringify} from "query-string";

/**
 * @param {any[]} arr
 * @param {string} key
 * @return {string}
*/
const generateQueryStringArr = (arr, key) => {
    const replaceValue = new RegExp(key, 'g');

    const strArr = stringify({
        [key]: arr,
    });
    return strArr.replace(replaceValue, `${key}[]`);
};

export default generateQueryStringArr;