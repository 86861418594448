import React, {memo} from 'react';
import './style/page_loading.scss';

function PageLoading () {
    return (
        <div className="page_loader_container">
            <span className="page_loader"></span>
        </div>
    )
}

export default memo(PageLoading)