export const permissionDenied = '1';

export const getPermission = (userRole, type) => {
    switch (type) {
        case 'protected':
            return userRole.isAdmin;
        case 'private':
            return userRole.isAdmin || userRole.isSeniorManager || userRole.isManager;
        default:
            return userRole.isMember || userRole.isAdmin || userRole.isSeniorManager || userRole.isManager;
    }
};