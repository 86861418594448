import {notification} from "antd";

const useNotification = (type, title, description) => {
    notification[type]({
        message: title,
        description: description,
        className: `notif_${type}`,
        duration: 6
    });
};

export default useNotification;
