import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {TransitionGroup, CSSTransition, SwitchTransition} from "react-transition-group";
import {Redirect} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useRecoilState, useSetRecoilState} from "recoil";
import axios from "axios";

// Components
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword.js";
import Footer from 'sharedComponents/Footer/Footer.jsx';
import WrongCompany from "./components/WrongCompany";
import PageLoading from "sharedComponents/Loading/PageLoading";

// Hooks
import useQuery from "../../../Hooks/useQuery";
import {useAuth} from "context/auth";
import useResetRecoil from "Hooks/useResetRecoil";

// Utils
import {
    companyData,
    companyLogo,
    isUserFirstLoggedIn,
    userMe,
} from "store"
import {authStates} from "./Login.config";
import validate from "utils/validate";
import {validationLength} from "constants/validationLength";

// Assets
import eleapLogo from "../../../assets/images/eLeaP_logo.svg";

// Styles
import "./style/login.scss";
import LoadingSpinner from "../../../sharedComponents/LoadingSpinner";


const Login = () => {
    const query = useQuery();

    const setUser = useSetRecoilState(userMe);
    const [companyWholeData, SetCompanyData] = useRecoilState(companyData);
    const [companyError, SetCompanyError] = useState(false);
    const [companyLoading, SetCompanyLoading] = useState(false);
    const SetIsFirstLogin = useSetRecoilState(isUserFirstLoggedIn);
    const [logo] = useRecoilState(companyLogo);
    const {handleSubmit, register, errors} = useForm();
    const [logoSrc, SetLogoSrc] = useState('');
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, SetIsError] = useState(false);
    const [errorMessage, SetErrorMessage] = useState('');
    const [authState, SetAuthState] = useState(authStates.loginState);
    const {setAuthTokens} = useAuth();
    const [startedLogin, SetStartedLogin] = useState(false);
    const [loginLoading, SetLoginLoading] = useState(false);

    const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let host = window.location.host;
    let parts = host.split(".");
    let company = "";

    const isDefaultLogo = logoSrc === eleapLogo;

    const resetRecoil = useResetRecoil();

    if (parts.length >= 2) {
        company = parts[0];
    }

    // The '+' symbol in query parameters is interpreted as a space (' ') by default.
    // To ensure emails with '+' are correctly handled, we replace spaces with '+'.
    const emailQuery = query.get('email')?.replace(/ /g, '+');
    const tokenQuery = query.get('token');

    useEffect(() => {
        let isCancelled = false;
        if (!Object.keys(companyWholeData).length && !isCancelled) {
            SetCompanyError(false);

            // showing loader when the user has been entered to the another company
            if (company && localStorage.getItem('domain') !== company) {
                SetCompanyLoading(true);
            }

            axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}company?company=${company}`
            }).then((response) => {
                localStorage.setItem('domain', response.data?.domain);
                SetCompanyData(response.data);
                response.data.logo_path && SetLogoSrc(response.data.logo_path);
            }, (error) => {
                SetCompanyError(true);
                throw new Error(`Something went wrong with company request(Login page), ${error}`)
            }).finally(() => {
                SetCompanyLoading(false);
            });
        }
        return () => {
            isCancelled = true;
        };
    }, [company, companyWholeData, SetCompanyData]);

    useLayoutEffect(() => {
        if (tokenQuery && emailQuery) {
            SetAuthState(authStates.resetState);
        } else {
            SetAuthState(authStates.loginState);
        }
    }, [tokenQuery, emailQuery]);

    const handleSubmitLogin = useCallback((values) => {
        let isCancelled = false;
        if (!isCancelled) {

            const data = { company }

            if (typeof values === 'object') {
                data.email = values.email;
                data.password = values.password;
            } else if (typeof values === 'string') {
                data.jwt_token = values;
            }

            axios.post(process.env.REACT_APP_API_URL + "apm_login", data).then(result => {
                if (result.status === 200) {
                    resetRecoil();
                    setAuthTokens(result.data.token);
                    setLoggedIn(true);
                    SetIsFirstLogin(result.data.is_first);
                    setUser(result.data.user);
                    SetLoginLoading(false);
                } else {
                    SetIsError(true);
                }
            }).catch((e) => {
                if (e?.response?.data?.error_message) {
                    SetErrorMessage(e.response.data.error_message);
                }

                SetIsError(true);
            });
        }
        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line
    }, [company, setAuthTokens]);

    useEffect(() => {
        if (logoSrc === '' && (companyWholeData.logo_path || eleapLogo)) {
            const img = new Image();
            img.src = logo && !logo?.includes('eLeaP_logo') ? logo : companyWholeData.logo_path || companyWholeData.logo;
            img.onload = () => {
                SetLogoSrc(img.src);
            }
            img.onerror = () => {
                SetLogoSrc(eleapLogo);
            }
        }
        // eslint-disable-next-line
    }, [companyWholeData]);

    if (isLoggedIn) {
        return <Redirect to='/'/>;
    }

    if (!startedLogin) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('redeapp_jwt');
        if (token) {
            SetLoginLoading(true)
            handleSubmitLogin(token);
            SetStartedLogin(true);
        }
    }

    const handleEntering = (element) => {
        requestAnimationFrame(() => {
            element.style.opacity = 1;
            element.style.transform = "scale(1,1) rotateX(0)";
        });
    };

    const handleForgotPassword = () => {
        SetAuthState((prevState) => prevState === authStates.forgotState ? authStates.loginState : authStates.forgotState);
    };

    const pageContent = () => {
        switch (authState) {
            case authStates.resetState: {
                return (
                    <ResetPassword token={tokenQuery} email={emailQuery} />
                )
            }
            case authStates.forgotState: {
                return (
                    <ForgotPassword changeStatus={handleForgotPassword}/>
                );
            }
            case authStates.loginState:
            default: {
                return (
                    !loginLoading ?
                        (
                            <form
                                className="login_form"
                                onSubmit={handleSubmit(handleSubmitLogin)}
                            >
                                <div className={`wrong_credentials ${isError ? 'visible' : ''}`}>
                                    <span className="warning"/>
                                    <h3>{errorMessage || 'Your email or password was invalid'}</h3>
                                </div>
                                <h2 className="login_title">Login</h2>
                                <div className={`form_item ${errors.email ? "invalid" : ""}`}>
                                    <label htmlFor="email">Email<sup>*</sup></label>
                                    <input
                                        ref={register({
                                            validate: validate({
                                                required: true,
                                                max: {value: validationLength.small, message: `Text is too long (maximum is ${validationLength.small} characters)`},
                                                custom: (value) => value.match(emailValidation) || "invalid email address"
                                            }),
                                        })}
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email address"
                                        className="login_input"
                                    />
                                    <div className="error_sect">
                                        {errors.email && errors.email.message}
                                    </div>
                                </div>
                                <div className={`form_item ${errors.password ? "invalid" : ""}`}>
                                    <label htmlFor="password">Password<sup>*</sup></label>
                                    <input
                                        ref={register({
                                            validate: validate({
                                                required: true,
                                                min: {value: 8, message: 'At least 8 characters needed'},
                                                max: {value: validationLength.small, message: `Text is too long (maximum is ${validationLength.small} characters)`},
                                            }),
                                        })}
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Enter your password"
                                        className="login_input"
                                    />
                                    <div className="error_sect">
                                        {errors.password && errors.password.message}
                                    </div>
                                </div>
                                <div className="login_actions">
                                    <button
                                        type="submit"
                                        className="primary_action"
                                    >
                                        Login
                                    </button>
                                    <button
                                        type="button"
                                        className="secondary_action"
                                        onClick={handleForgotPassword}
                                    >
                                        Forgot password
                                    </button>
                                </div>
                            </form>
                        ) :
                        (
                            <div className="login_form">
                                <LoadingSpinner/>
                            </div>
                        )

                );
            }
        }
    };

    if (companyLoading) {
        return (
            <div className="login_page_container">
                <PageLoading />
            </div>
        );
    }

    if (!company || companyError) {
        return (
            <WrongCompany />
        );
    }

    return (
        <div className="login_page_container">
            <TransitionGroup className="form_box_wrapper">
                <CSSTransition
                    enter={true}
                    exit={true}
                    appear={true}
                    timeout={600}
                    onEntering={handleEntering}
                >
                    <div className="form_box">
                        <div className="company_container">
                            <div className={`company_image_wrapper ${isDefaultLogo ? 'company_image_wrapper_default' : ''}`}>
                                <img
                                    src={logoSrc}
                                    alt="logo"
                                    className="company_image"
                                    width="88"
                                    height="48"
                                />
                                {isDefaultLogo && <div className="company_logo_text">PMP</div>}
                            </div>
                        </div>
                        <SwitchTransition>
                            <CSSTransition
                                key={authState === authStates.forgotState ? "1" : "2"}
                                classNames='animation'
                                timeout={300}
                                unmountOnExit={true}
                            >
                                {pageContent()}
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <Footer footerLogo={eleapLogo}/>
        </div>
    );
}

export default React.memo(Login);
