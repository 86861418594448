import {
    userMe,
    sidebarCollapsed,
    isSidebarVisible,
    isUserFirstLoggedIn,
    companyData,
    generalCurrentSettings,
    isQuickTourActive,
    editableSurveyData,
    surveyTeamReceivers,
    surveyUsersReceivers,
    unreadNotificationsCount,
    gamificationDataCount,
    dateIntervalDataDashboard, fileDownloading,
} from "store";
import {selector} from "recoil";

export const role = selector({
    key: "role",
    get: ({get}) => {
        const accountData = get(userMe);
        return {
            roleId: accountData.id_apm_acl_role || 3,
            get isAdmin() {
                return this.roleId === 1;
            },
            get isSuperAdmin() {
                return this.roleId === 777;
            },
            get isManager() {
                return this.roleId === 2;
            },
            get isSeniorManager() {
                return this.roleId === 8;
            },
            get isMember() {
                return this.roleId === 3;
            }
        }
    }
});

export const selectMe = selector({
    key: 'selectMe',
    get: ({ get }) => {
        return get(userMe)
    }
})

export const selectRole = selector({
    key: 'selectRole',
    get: ({ get }) => {
        return get(role)
    }
})

export const selectSidebarCollapsed = selector({
    key: 'selectSidebarCollapsed',
    get: ({ get }) => {
        return get(sidebarCollapsed)
    }
})
export const selectIsUserFirstLoggedIn = selector({
    key: 'selectIsUserFirstLoggedIn',
    get: ({ get }) => {
        return get(isUserFirstLoggedIn)
    }
})

export const selectIsSidebarVisible = selector({
    key: 'selectIsSidebarVisible',
    get: ({ get }) => {
        return get(isSidebarVisible)
    }
})

export const selectCompanyData = selector({
    key: 'selectCompanyData',
    get: ({ get }) => {
        return get(companyData)
    }
})

export const selectGeneralCurrentSettings = selector({
    key: 'selectGeneralCurrentSettings',
    get: ({ get }) => {
        return get(generalCurrentSettings)
    }
})

export const selectIsQuickTourActive = selector({
    key: 'selectIsQuickTourActive',
    get: ({ get }) => {
        return get(isQuickTourActive)
    }
})
export const selectSurveyUsersReceivers = selector({
    key: 'selectSurveyUsersReceivers',
    get: ({ get }) => {
        return get(surveyUsersReceivers)
    }
})
export const selectSurveyTeamReceivers = selector({
    key: 'selectSurveyTeamReceivers',
    get: ({ get }) => {
        return get(surveyTeamReceivers)
    }
})
export const selectEditableSurveyData = selector({
    key: 'selectEditableSurveyData',
    get: ({ get }) => {
        return get(editableSurveyData)
    }
})
export const selectUnreadNotificationsCount = selector({
    key: 'selectUnreadNotificationsCount',
    get: ({ get }) => {
        return get(unreadNotificationsCount)
    }
})
export const selectGamificationDataCount = selector({
    key: 'selectGamificationDataCount',
    get: ({ get }) => {
        return get(gamificationDataCount)
    }
})
export const selectDateIntervalDataDashboard = selector({
    key: 'selectDateIntervalDataDashboard',
    get: ({ get }) => {
        return get(dateIntervalDataDashboard)
    }
})
export const selectFileDownloading = selector({
    key: 'selectFileDownloading',
    get: ({ get }) => {
        return get(fileDownloading)
    }
})
