import {useState, useEffect} from "react";


const useDisableDate = (dateRange, recurringInterval) => {
    const [disabledRepeatInput, SetDisabledRepeatInput] = useState(1);


    useEffect(() => {
        const diffDay = dateRange?.[1]?.diff(dateRange?.[0], 'days');

        if (recurringInterval === 'days') {
            SetDisabledRepeatInput(diffDay + 1);
        }

        if (recurringInterval === 'weeks') {
            if (diffDay > 7) {
                SetDisabledRepeatInput(Math.trunc(diffDay / 7) + 1);
            } else {
                SetDisabledRepeatInput(1);
            }
        }

        if (recurringInterval === 'months') {
            if (diffDay > 30) {
                SetDisabledRepeatInput(Math.trunc(diffDay / 30) + 1);
            } else {
                SetDisabledRepeatInput(1);
            }
        }

        if (recurringInterval === 'years') {
            if (diffDay > 365) {
                SetDisabledRepeatInput(Math.trunc(diffDay / 365) + 1);
            } else {
                SetDisabledRepeatInput(1);
            }
        }
    }, [dateRange, recurringInterval]);

    return {
        disabledRepeatInput,
    };
};

export default useDisableDate;