import {useEffect} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {gamificationDataCount, generalCurrentSettings} from 'store';
import {generalSetting} from "models/generalSetting";
import {myGamification} from "models/myGamification";
import {dateFormats} from "../pages/CompanySettings/components/GeneralSettings/GeneralSettings.config";

const useGetGeneralDateFormat = () => {
    const [generalSettings, setGeneralSettings] = useRecoilState(generalCurrentSettings);
    const setGamificationCount = useSetRecoilState(gamificationDataCount);

    useEffect(() => {
        if(!Object.keys(generalSettings).length) {
            generalSetting.getGeneralSetting().then((res) => {
                if(res?.data?.success) {
                    setGeneralSettings(res.data.general_setting);
                }
            });

            myGamification.getUserGamificationCount().then((res) => {
                if(res?.data?.success) {
                    setGamificationCount(res.data);
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    const {date_format: dateFormat = dateFormats[2].value} = generalSettings;

    return dateFormats.find((df) => df.value === dateFormat)?.label || dateFormats[1].label;
};

export default useGetGeneralDateFormat;