import request from "./utils/request";

export const myGamification = {
    getUserGamificationCount: () => {
        return request('GET', 'user_gamification_count');
    },
    getLeaderBoard: (page = 1) => {
        return request('GET', `leader_board?page=${page}`);
    },
    getBadges: () => {
        return request('GET', 'badges');
    },
    getGamificationActions: () => {
        return request('GET', 'gamification_actions');
    },
    getUserGamification: (page= 1, start = '', end = '', action = '', sortBy = 'desc') => {
        return request('GET', `user_gamification?page=${page}&start=${start}&end=${end}&action=${action}&sortByType=${sortBy}`);
    },
    getSpecificUserGamification: (id = '', page= 1, start = '', end = '', action = '', sortBy = 'desc') => {
        return request('GET', `user_gamifications/${id}?page=${page}&start=${start}&end=${end}&action=${action}&sortByType=${sortBy}`);
    },
    getSpecificUserGamificationCount: (id = '') => {
        return request('GET', `user_gamifications_count/${id}`);
    },
    getSpecificUserBadges: (id = '') => {
        return request('GET', `user_badges/${id}`);
    },
}