import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {parse} from "query-string";
import {useSetRecoilState} from "recoil";

// Utils
import {google} from "models/google";
import {googleCalendarConnectedState} from "store";


const useCalendarToken = () => {
    const history = useHistory();
    const location = useLocation();

    const setCalendarConnected = useSetRecoilState(googleCalendarConnectedState);

    const addToken = async () => {
        try {
            const search = parse(location.search);

            if (!search.scope || !search.code) {
                return;
            }

            history.push(`${process.env.REACT_APP_URL_PREFIX}${location.pathname}`);

            const response = await google.addCalendarToken(search);

            if (!response?.data?.status) {
                throw new Error('Something went wrong');
            }

            setCalendarConnected({
                connected: true,
                requested: true,
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (location.search) {
            addToken();
        }
    }, [location]);
};

export default useCalendarToken;
