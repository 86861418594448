import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

// Hooks
import useDebounce from "./useDebounce";

// Utils
import {tagging} from "models/tagging";
import {useRecoilValue} from "recoil";

// Store
import {userMe} from "store";


const defaultFormatter = (item) => {
    return {
        id: item.id,
        value: item.name,
        avatar: item.avatar
    };
};

const sections = {
    goals: 'getTagsGoalsKRs',
    checkin: 'getCheckins',
    posts: 'getPosts',
    weeklyStatus: 'getWeeklyStatusComments',
    pulsePoint: 'getPulsePointUsers',
    surveys: 'getSurveysUsers',
};

const useTag = (section = 'goals', options = {}) => {
    const {
        dataFormatter = defaultFormatter,
        userId,
    } = options;

    const {id: paramId} = useParams();

    const me = useRecoilValue(userMe);
    const [value, SetValue] = useState('');
    const [chosenList, SetChosenList] = useState([]);
    const [chosenListValues, SetChosenListValues] = useState({});
    const [foundList, SetFoundList] = useState([]);
    const [isSelected, SetIsSelected] = useState(false);
    const [hasPermissionToTag, SetHasPermissionToTag] = useState(true);

    const id = userId || paramId || me?.id;

    const tagRegEx = /@/;

    const handleChange = (e) => {
        SetValue(e.target.value);

        const isBackspace = e.target.value.length < value.length;

        // Detecting "@" input
        if (e.target.value.at(-1) === '@' && !isBackspace) {
            SetIsSelected(false);
        }

        // Detecting tag remove
        let foundId;
        const endsWithQueryString = Object
            .entries(chosenListValues)
            .some(([listKey, listValue]) => {
                foundId = listKey;
                return value.endsWith(`@${listValue}`);
            });

        if (endsWithQueryString && isBackspace) {
            SetValue(value.replace(tagRegEx, ''));
            SetChosenList((prevState) => prevState.filter((item) => item !== foundId));
        }
    };


    const handleSelect = (e) => {
        const {value: itemValue, label} = e.currentTarget.dataset;
        const splitValue = value.split(' ');

        SetChosenList((prevState) => [itemValue, ...prevState]);
        SetChosenListValues((prevState) => ({[itemValue]: label, ...prevState}));
        SetValue(value.replace(splitValue[splitValue.length - 1], '@') + splitValue[splitValue.length - 1].replace(splitValue[splitValue.length - 1], label));
        SetIsSelected(true);
    };


    const debouncedCommentValue = useDebounce(value, 500);


    const handleSearchTaggedUser = async (id, name) => {
        try {
            const response = await tagging[sections[section]](id, name);
            if (response?.data?.success) {
                SetFoundList(response.data.users);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleResetChosenUsersList = () => {
        SetChosenList([]);
    };

    useEffect(() => {
        const splitedValue = debouncedCommentValue.split('@');
        const name = splitedValue.at(-1);
        const beforeName = splitedValue.at(-2) || '';

        if (tagRegEx.test(debouncedCommentValue) && (!beforeName || beforeName[beforeName.length - 1] === ' ') && hasPermissionToTag) {
            handleSearchTaggedUser(id, name);
        } else {
            foundList.length && SetFoundList([]);
        }
        // eslint-disable-next-line
    }, [debouncedCommentValue]);

    // useEffect(() => {
    //     if (!value.includes('@')) {
    //         SetChosenList([]);
    //     }
    // }, [value]);

    // eslint-disable-next-line
    const formattedList = useMemo(() => foundList.map(dataFormatter), [foundList]);

    return {
        value,
        SetValue,
        chosenList,
        SetChosenList,
        SetChosenListValues,
        handleChange,
        handleSelect,
        handleResetChosenUsersList,
        foundList,
        formattedList,
        isSelected,
        SetIsSelected,
        SetHasPermissionToTag,
    };
};

export default useTag;
