const validate = ({required, max, min, custom}) => {
    const validation = {};

    if (required) {
        validation.required = (value) => {
            if (!value.trim()) {
                if (typeof required === 'boolean' && required) {
                    return 'Required';
                }

                return required;
            }
        };
    }

    if (max) {
        validation.max = (value) => {
            if (value.trim().length > max.value) {
                return max.message;
            }
        };
    }

    if (min) {
        validation.min = (value) => {
            if (value.trim().length < min.value) {
                return min.message;
            }
        };
    }

    if (custom) {
        validation.validate = custom;
    }

    return validation;
};

export default validate;