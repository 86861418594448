import request from "./utils/request";

export const auth = {
    login: (data) => {
        return request('POST', 'apm_login', data);
    },
    logout: () => {
        return request('GET', 'logout_user');
    },
    sendResetPasswordLink: (email, domain = '') => {
        return request('POST', 'password/email', {email, domain});
    },
    resetPassword: (data) => {
        return request('POST', 'password/reset', data);
    },
    checkFreeTrial: () => {
        return request('GET', 'check_free_trial');
    },
    checkUnpaidInvoices: () => {
        return request('GET', 'unpaid_invoice');
    },
};
