import request from "./utils/request";

export const generalSetting = {
    getLanguagesGeneralSetting: () => {
        return request('GET', `company_settings/languages`);
    },
    getTimeZonesGeneralSetting: () => {
        return request('GET', `company_settings/time_zones`);
    },
    getGeneralSetting: () => {
        return request('GET', `company_settings`);
    },
};