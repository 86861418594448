import request from "./utils/request";

export const google = {
    checkCalendarConnection: () => {
        return request('GET', 'google/checkCalendarConnection');
    },
    addCalendarToken: (data) => {
        return request('POST', 'checkins/add_calendar_token', data);
    },
    calendarServicesConnection: (data) => {
        return request('POST', 'calendar_service', data);
    },
};
