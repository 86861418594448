import React from "react";

// Styles
import './style/not_found_ui.scss';


const NotFoundUI = () => {
    return (
        <div className="not_found_ui">
            <h5 className="not_found_ui_heading">
                404
            </h5>
            <p className="not_found_ui_content">
                Sorry, this page isn't available or you might not have permission to view it.
            </p>
        </div>
    );
};

export default NotFoundUI;