import {useEffect} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";

import {gamificationDataCount, generalCurrentSettings} from 'store';
import {generalSetting} from "models/generalSetting";
import {myGamification} from "../models/myGamification";


const useGeneralSettings = () => {
    const [generalSettings, setGeneralSettings] = useRecoilState(generalCurrentSettings);
    const setGamificationCount = useSetRecoilState(gamificationDataCount);

    useEffect(() => {
        if (generalSettings?.id) {
            return;
        }

        generalSetting.getGeneralSetting().then((res) => {
            if (res?.data?.success) {
                setGeneralSettings(res.data.general_setting);
            }
        });

        myGamification.getUserGamificationCount().then((res) => {
            if(res?.data?.success) {
                setGamificationCount(res.data);
            }
        });
    }, []);

    return [generalSettings, setGeneralSettings];
};

export default useGeneralSettings;
