import {useEffect} from "react";
import {useRecoilState} from "recoil";

// Store
import {surveyCompletionStatuses} from "store";


const useDropoutSurveyField = (firstValue, secondValue, handler) => {
    const [isClickedOnce, SetIsClickedOnce] = useRecoilState(surveyCompletionStatuses);

    useEffect(() => {
        if ((firstValue || secondValue?.length) && !isClickedOnce && handler) {
            handler('dropouts');
            handler('starts');
            SetIsClickedOnce(true);
        }
    }, [firstValue, secondValue]);
};

export default useDropoutSurveyField;