import React, {useState} from 'react';

const useIntercom = () => {
    const [initialized, setInitialized] = useState(false);

    const initialize = () => {
        if(initialized) {
            return
        }
        window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "my1hjhob",
            alignment: 'left',
            hide_default_launcher: true,
        };
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/my1hjhob';
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
        setInitialized(true);
    }

    return {
        initialize
    }
}
export default useIntercom;