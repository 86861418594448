import {socket} from "sharedComponents/Socket/socket";
import parseJsonSafely from './parseJsonSafely';

/**
 * @param {string} name
 * @param {object} data
*/
const emitSocket = (name, data = {}) => {
  const user = parseJsonSafely(localStorage.getItem('user'));

  if (!user?.id_company) {
    return;
  }

  const company = user.id_company;

  socket.emit(name, {
    data,
    company,
    id: socket.id,
  });
};

export default emitSocket;
