import React, {useCallback} from 'react';
import {useForm} from "react-hook-form";
import {useRecoilValue} from "recoil";
import useNotification from "../../../../Hooks/useNotification";

// Utils
import {auth} from "models/auth";
import validate from "../../../../utils/validate";
import {validationLength} from "../../../../constants/validationLength";

// Store
import {companyData} from "store";

const ForgotPassword = ({changeStatus}) => {
    const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const {handleSubmit, register, errors} = useForm();
    const successNote = useNotification;
    const errorNote = useNotification;

    const companyWholeData = useRecoilValue(companyData);

    const handleSubmitRecoveryLink = ({email}) => {
        auth.sendResetPasswordLink(email, companyWholeData.domain).then((res) => {
            if (res?.data?.success) {
                successNote(
                    'success',
                    'Recovery link was sent',
                    res.data.message,
                );
            } else {
                errorNote(
                    "error",
                    "Warning!",
                    res.data.message,
                )
            }
        });
    };

    const handleGoBack = useCallback(() => {
        changeStatus();
    }, [changeStatus]);

    return (
        <form
            onSubmit={handleSubmit(handleSubmitRecoveryLink)}
            className="login_form forgot_password_form"
        >
            <h2 className="login_title">Recover your password</h2>
            <p className="forgot_password_text">
                It happens to everyone, so just enter your email address bellow and we'll send you a recovery link.
            </p>
            <div className={`form_item ${errors.email ? "invalid" : ""}`}>
                <label htmlFor="email">Email address<sup>*</sup></label>
                <input
                    ref={register({
                        validate: validate({
                            required: true,
                            max: {value: validationLength.small, message: `Text is too long (maximum is ${validationLength.small} characters)`},
                            custom: (value) => value.match(emailValidation) || "invalid email address"
                        }),
                    })}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    className="login_input"
                />
                <div className="error_sect">
                    {errors.email && errors.email.message}
                </div>
            </div>
            <div className="actions_sect">
                <button
                    type="submit"
                    className="primary_action"
                >
                    Send recovery link
                </button>
                <button
                    onClick={handleGoBack}
                    type="button"
                    className="secondary_action"
                >
                    Get back to the login page
                </button>
            </div>
        </form>
    )
}

export default React.memo(ForgotPassword)