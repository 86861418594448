import React, {useEffect, useLayoutEffect, useState, memo} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";

// Components
import LoadingSpinner from "sharedComponents/LoadingSpinner";

// Hooks
import useNotification from "Hooks/useNotification";

// Utils
import {auth} from "models/auth";

// Contexts
import {useAuth} from "context/auth.js";

const ResetPassword = ({token, email}) => {
    const history = useHistory();
    const resetPasswordSuccessNote = useNotification;
    const {removeAuthTokens} = useAuth();
    const [isCounterVisible, SetIsCounterVisible] = useState(false);
    const [timer, SetTimer] = useState(3);

    const {handleSubmit, register, errors, getValues} = useForm();

    let timerInterval = null;

    useLayoutEffect(() => {
        removeAuthTokens();
        // eslint-disable-next-line
    }, []);

    const submitForm = async (data) => {
        try {
            const sendData = {
                ...data,
                token,
                email,
            };

            const {data: responseData} = await auth.resetPassword(sendData);

            if (responseData?.success) {
                SetIsCounterVisible(responseData.success);
                resetPasswordSuccessNote(
                    'success',
                    'Password reset',
                    responseData.message
                );
                timerInterval = setInterval(() => {
                    SetTimer((prevState) => prevState - 1);
                }, 1000);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (timer === 0) {
            clearInterval(timerInterval);
            history.push(`${process.env.REACT_APP_URL_PREFIX}/login`);
        }
        // eslint-disable-next-line
    }, [timer]);


    return (
        <form className="login_form reset_password_form" onSubmit={handleSubmit(submitForm)}>
            <h2 className="login_title">Reset password</h2>
            <div className={`form_item ${errors.password ? "invalid" : ""}`}>
                <label htmlFor="password">Password</label>
                <input
                    ref={register({
                        required: "Required",
                        validate: (value) => value.length >= 8 || 'At least 8 characters needed',
                    })}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    className="login_input"
                />
                <div className="error_sect">
                    {errors.password && errors.password.message}
                </div>
            </div>
            <div className={`form_item ${errors.password_confirmation ? "invalid" : ""}`}>
                <label htmlFor="password_confirmation">Password confirmation</label>
                <input
                    ref={register({
                        required: "Required",
                        validate: (value) => value === getValues().password || 'Passwords do not match'
                    })}
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Confirm password"
                    className="login_input"
                />
                <div className="error_sect">
                    {errors.password_confirmation && errors.password_confirmation.message}
                </div>
            </div>
            <div className="login_actions">
                <button
                    type="submit"
                    className="primary_action"
                >
                    Reset
                </button>
                {isCounterVisible && <p className="redirect_message">
                    <LoadingSpinner size="small"/>
                    <span className="redirect_message_content">You Will be redirected after {timer} seconds</span>
                </p>}
            </div>
        </form>
    );
};

export default memo(ResetPassword);