import React from 'react';

const WrongCompany = () => {
    return (
        <div className="login_page_container">
            <div className="wrong_company">
                <h1 className="wrong_company_title">Sorry, the account doesn't exist.</h1>
                <h2 className="wrong_company_subtitle">Check your eLeaP PMP account website URL to make sure it is correct, or contact eLeaP PMP support for any questions.</h2>
            </div>
        </div>
    );
};

export default WrongCompany;
