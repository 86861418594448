import {
    atRisk,
    completed,
    exceeded,
    offTrack,
    onTrack, progressed
} from "../App/components/Dashboard/components/DashboardContent.config";

export const getPercentage = (sect, goalStatuses) => {
    if (goalStatuses && goalStatuses.goals_statuses_count) {
        const sum = goalStatuses.goals_total_count;
        // get the percentage and then trunc the result to the first floating point number
        if (sect === onTrack) {
            return !!sum ? goalStatuses?.goals_statuses_count?.on_track?.percent : sum;
        } else if (sect === atRisk) {
            return !!sum ? goalStatuses?.goals_statuses_count?.at_risk?.percent : sum;
        } else if (sect === offTrack) {
            return !!sum ? goalStatuses?.goals_statuses_count?.off_track?.percent : sum;
        } else if (sect === exceeded) {
            return !!sum ? goalStatuses?.goals_statuses_count?.exceeded?.percent : sum;
        } else if (sect === completed) {
            return !!sum ? goalStatuses?.goals_statuses_count?.completed?.percent : sum;
        } else if (sect === progressed) {
            return goalStatuses?.progressed;
        }
    }
}
