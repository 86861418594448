import {useResetRecoilState} from "recoil";
import {
    accomplishmentsDashboardPaginated,
    actionsState,
    addedOrDeletedPostData,
    addedOrRemovedKeyResult,
    addedOrRemovedPeople,
    allWSItemsPaginated,
    areTeamsAndPeopleFiltered,
    attachedFilesTotal,
    auditTrailFiltered,
    auditTrailFilters,
    canMemberAddCheckin,
    categoriesState,
    challengesDashboardPaginated,
    chartsData,
    checkinItemAssigneesList,
    chosenSurveyFilter,
    comingUpDashboardPaginated,
    companyAllUsers,
    isFreeTrial,
    isYourCompanyBlocked,
    currentlyActiveIndex,
    currentlyActiveModule,
    currentlyActiveSettings,
    dateIntervalDataDashboard,
    dateIntervalDataReviews,
    entryReviewsDateInterval,
    dateIntervalDataWS,
    editableCheckIn,
    editableCheckInData,
    editableFormTemplate,
    editableGoalData,
    editablePostData,
    editableSurveyData,
    editableTeam,
    editableTeamMember,
    editableUserData,
    editedCheckInData,
    editedGoalData,
    editedTeamData,
    fileDownloading,
    filterFilteredValues,
    filterSortByValue,
    filterSortingMethod,
    formTemplatesDataPaginated,
    GoalCommentPaginatedData,
    GoalCommentShortData,
    goalsByStatuses,
    goalsData,
    goalsStatusData,
    goalsStatusWSData,
    goalStoreTimeLineData,
    isActivityNeedToUpdate,
    isAnonymousUser,
    isDateIntervalLoad,
    isFilteredMy,
    isFilteredReceived,
    isGoalsFiltered,
    isQuickTourActive,
    isSelectedTeam,
    isSidebarVisible,
    isSurveyAddedOrganization,
    isUserFirstLoggedIn,
    isWSByUserFiltered,
    loadingMiddleware,
    meetingsData,
    meetingsFiltered,
    notificationsAllData,
    notificationsPageData,
    notificationsVisible,
    peoplePagination,
    plansDashboardPaginated,
    receivedReviewListPaginated,
    reviewGeneralSettings,
    reviewListPaginated,
    reviewUpcomingListPaginated,
    reviewPeriodSettings,
    isModulesRequested,
    reviewPeriodTimelineShow,
    reviewsActiveTabKey,
    selectedPostsPage,
    selectUsersData,
    specificGoalsData,
    specificGoalShortData,
    specificReviewUser,
    submittedReviewsData,
    surveyIsFiltered,
    surveyPreviewStatus,
    surveysDataPaginated,
    surveyStatuses,
    surveyTeamReceivers,
    surveyUsersReceivers,
    teamsAndPeopleActiveKey,
    teamsData,
    teamsPagination,
    totalComments,
    unreadNotificationsCount,
    userCustomFields,
    userMe,
    userReviewers,
    userReviewsData,
    weeklyStatusActiveTab,
    googleCalendarConnectedState,
    microsoftOutlookCalendarConnectedState,
    customUnitsData,
    feedsData,
    feedsHighlightData,
    feedsDataCount,
    surveyActiveTab,
    pulsePointActiveTab,
    dateIntervalSurvey,
    pulsePointDateInterval,
    dateIntervalCheckIn,
    teamsActionsState,
    webhookActiveTab,
    reviewCheckedPeriod,
    selectedBillingMenuItem,
    eNPSSurveyData,
    eNPSCurrentSurveyData,
    getENPSCompletionStatuses,
    eNPSDateInterval,
    enpsPeriodTimelineShow,
    reviewsTotalCount,
    reviewsActiveColumn,
} from "../store";


const useResetRecoil = () => {
    const resetUser = useResetRecoilState(userMe);
    const resetAllUsersData = useResetRecoilState(companyAllUsers);
    const resetFreeTrialData = useResetRecoilState(isFreeTrial);
    const resetCompanyBlockedData = useResetRecoilState(isYourCompanyBlocked);
    const resetIsQuickTourActive = useResetRecoilState(isQuickTourActive);

    const resetTeamsAndPeopleActiveKey = useResetRecoilState(teamsAndPeopleActiveKey);
    const resetAllUsersPaginated = useResetRecoilState(peoplePagination);
    const resetAddedOrRemovedPeople = useResetRecoilState(addedOrRemovedPeople);
    const resetEditableUserData = useResetRecoilState(editableUserData);
    const resetTeams = useResetRecoilState(teamsData);
    const resetTeamsPaginated = useResetRecoilState(teamsPagination);
    const resetEditableTeam = useResetRecoilState(editableTeam);
    const resetEditedTeamData = useResetRecoilState(editedTeamData);
    const resetEditableTeamMember = useResetRecoilState(editableTeamMember);
    const resetIsSelectedTeam = useResetRecoilState(isSelectedTeam);
    const resetIsActivityNeedToUpdate = useResetRecoilState(isActivityNeedToUpdate);
    const resetAddedOrDeletedPostData = useResetRecoilState(addedOrDeletedPostData);
    const resetEditablePostData = useResetRecoilState(editablePostData);
    const resetSelectedPostsPage = useResetRecoilState(selectedPostsPage);
    const resetSelectUsersData = useResetRecoilState(selectUsersData);
    const resetAreTeamsAndPeopleFiltered = useResetRecoilState(areTeamsAndPeopleFiltered);

    const resetIsUserFirstLoggedIn = useResetRecoilState(isUserFirstLoggedIn);
    const resetUserReviewsData = useResetRecoilState(userReviewsData);
    const resetUserReviewers = useResetRecoilState(userReviewers);

    const resetEditableCheckInData = useResetRecoilState(editableCheckInData);
    const resetEditedCheckInData = useResetRecoilState(editedCheckInData);
    const resetMeetingData = useResetRecoilState(meetingsData);
    const resetMeetingsFiltered = useResetRecoilState(meetingsFiltered);
    const resetEditableCheckIn = useResetRecoilState(editableCheckIn);
    const resetCanMemberAddCheckin = useResetRecoilState(canMemberAddCheckin);
    const resetCheckinItemAssigneesList = useResetRecoilState(checkinItemAssigneesList);
    const resetDateIntervalCheckIn = useResetRecoilState(dateIntervalCheckIn);

    const resetReceivedTeamsData = useResetRecoilState(surveyTeamReceivers);
    const resetReceivedUsersData = useResetRecoilState(surveyUsersReceivers);
    const resetSurveyPaginatedData = useResetRecoilState(surveysDataPaginated);
    const resetEditableSurveyData = useResetRecoilState(editableSurveyData);
    const resetSurveyStatuses = useResetRecoilState(surveyStatuses);
    const resetSurveyIsFiltered = useResetRecoilState(surveyIsFiltered);
    const resetChosenSurveyFilter = useResetRecoilState(chosenSurveyFilter);
    const resetSurveyPreviewStatus = useResetRecoilState(surveyPreviewStatus);
    const resetIsSurveyAddedOrganization = useResetRecoilState(isSurveyAddedOrganization);
    const resetIsAnonymousUser = useResetRecoilState(isAnonymousUser);
    const resetSurveyActiveTab = useResetRecoilState(surveyActiveTab);
    const resetDateIntervalSurvey = useResetRecoilState(dateIntervalSurvey);
    const resetENPSSurveyData = useResetRecoilState(eNPSSurveyData);
    const resetENPSCurrentSurveyData = useResetRecoilState(eNPSCurrentSurveyData);
    const resetGetENPSCompletionStatuses = useResetRecoilState(getENPSCompletionStatuses);
    const resetENPSDateInterval = useResetRecoilState(eNPSDateInterval);
    const resetENPSPeriodTimelineShow = useResetRecoilState(enpsPeriodTimelineShow);

    const resetGoals = useResetRecoilState(goalsData);
    const resetEditableGoalData = useResetRecoilState(editableGoalData);
    const resetEditedGoalData = useResetRecoilState(editedGoalData);
    const resetGoalStatus = useResetRecoilState(goalsStatusData);
    const resetGoalsByStatuses = useResetRecoilState(goalsByStatuses);
    const resetIsGoalsFiltered = useResetRecoilState(isGoalsFiltered);
    const resetSpecificGoalShortData = useResetRecoilState(specificGoalShortData);
    const resetSpecificGoalsData = useResetRecoilState(specificGoalsData);
    const resetAddedOrRemovedKeyResult = useResetRecoilState(addedOrRemovedKeyResult);
    const resetGoalStoreTimeLineData = useResetRecoilState(goalStoreTimeLineData);
    const resetGoalCommentShortData = useResetRecoilState(GoalCommentShortData);
    const resetGoalCommentPaginatedData = useResetRecoilState(GoalCommentPaginatedData);
    const resetTotalComments = useResetRecoilState(totalComments);
    const resetAttachedFilesTotal = useResetRecoilState(attachedFilesTotal);

    const resetWeeklyStatusActiveTab = useResetRecoilState(weeklyStatusActiveTab);
    const resetWSDashboardAccomplishments = useResetRecoilState(accomplishmentsDashboardPaginated);
    const resetWSDashboardPlans = useResetRecoilState(plansDashboardPaginated);
    const resetWSDashboardChallenges = useResetRecoilState(challengesDashboardPaginated);
    const resetWSDashboardComingUp = useResetRecoilState(comingUpDashboardPaginated);
    const resetAllWSItemsPaginated = useResetRecoilState(allWSItemsPaginated);
    const resetDateIntervalDataWS = useResetRecoilState(dateIntervalDataWS);
    const resetIsDateIntervalLoad = useResetRecoilState(isDateIntervalLoad);
    const resetChartsData = useResetRecoilState(chartsData);
    const resetGoalsStatusWSData = useResetRecoilState(goalsStatusWSData);
    const resetFilterSortByValue = useResetRecoilState(filterSortByValue);
    const resetFilterSortingMethod = useResetRecoilState(filterSortingMethod);
    const resetFilterFilteredValues = useResetRecoilState(filterFilteredValues);
    const resetIsWSByUserFiltered = useResetRecoilState(isWSByUserFiltered);

    const resetFormTemplatesDataPaginated = useResetRecoilState(formTemplatesDataPaginated);
    const resetEditableFormTemplate = useResetRecoilState(editableFormTemplate);

    const resetReviewsActiveTabKey = useResetRecoilState(reviewsActiveTabKey);
    const resetDateIntervalDataReviews = useResetRecoilState(dateIntervalDataReviews);
    const resetEntryReviewsDateInterval = useResetRecoilState(entryReviewsDateInterval);
    const resetReviewListPaginated = useResetRecoilState(reviewListPaginated);
    const resetReviewUpcomingListPaginated = useResetRecoilState(reviewUpcomingListPaginated);
    const resetReceivedReviewListPaginated = useResetRecoilState(receivedReviewListPaginated);
    const resetSubmittedReviewsData = useResetRecoilState(submittedReviewsData);
    const resetIsFilteredMy = useResetRecoilState(isFilteredMy);
    const resetIsFilteredReceived = useResetRecoilState(isFilteredReceived);
    const resetReviewsTotalCount = useResetRecoilState(reviewsTotalCount);
    const resetReviewsActiveColumn = useResetRecoilState(reviewsActiveColumn);

    const resetReviewPeriodSettings = useResetRecoilState(reviewPeriodSettings);
    const resetSettingsModulesData = useResetRecoilState(isModulesRequested);
    const resetReviewGeneralSettings = useResetRecoilState(reviewGeneralSettings);
    const resetReviewPeriodTimelineShow = useResetRecoilState(reviewPeriodTimelineShow);
    const resetCurrentlyActiveSettings = useResetRecoilState(currentlyActiveSettings);
    const resetCurrentlyActiveIndex = useResetRecoilState(currentlyActiveIndex);
    const resetCurrentlyActiveModule = useResetRecoilState(currentlyActiveModule);
    const resetSpecificReviewUser = useResetRecoilState(specificReviewUser);
    const resetUserCustomFields = useResetRecoilState(userCustomFields);
    const resetReviewCheckedPeriod = useResetRecoilState(reviewCheckedPeriod);
    const resetSelectedBillingMenuItem = useResetRecoilState(selectedBillingMenuItem);

    const resetIsSidebarVisible = useResetRecoilState(isSidebarVisible);
    const resetLoadingMiddleware = useResetRecoilState(loadingMiddleware);

    const resetDateIntervalDataDashboard = useResetRecoilState(dateIntervalDataDashboard);

    const resetNotificationsVisible = useResetRecoilState(notificationsVisible);
    const resetNotificationsPageData = useResetRecoilState(notificationsPageData);
    const resetNotificationsAllData = useResetRecoilState(notificationsAllData);
    const resetUnreadNotificationsCount = useResetRecoilState(unreadNotificationsCount);

    const resetAuditTrailFiltered = useResetRecoilState(auditTrailFiltered);
    const resetAuditTrailFilters = useResetRecoilState(auditTrailFilters);

    const resetFileDownloading = useResetRecoilState(fileDownloading);

    const resetCategoriesState = useResetRecoilState(categoriesState);

    const resetActionsState = useResetRecoilState(actionsState);
    const resetGoogleCalendarConnectedState = useResetRecoilState(googleCalendarConnectedState);
    const resetOutlookCalendarConnectedState = useResetRecoilState(microsoftOutlookCalendarConnectedState);
    const resetCustomUnitsData = useResetRecoilState(customUnitsData);
    const resetFeedsData = useResetRecoilState(feedsData);
    const resetFeedsDataCount = useResetRecoilState(feedsDataCount);
    const resetFeedsHighlightData = useResetRecoilState(feedsHighlightData);

    const resetPulsePointActiveTab = useResetRecoilState(pulsePointActiveTab);
    const resetPulsePointDateInterval = useResetRecoilState(pulsePointDateInterval);
    const resetTeamsActionState = useResetRecoilState(teamsActionsState);
    const resetWebhookActiveTab = useResetRecoilState(webhookActiveTab);

    const reset = () => {
        // Global
        resetLoadingMiddleware();
        resetIsSidebarVisible();
        resetFileDownloading();
        resetFreeTrialData();
        resetCompanyBlockedData();

        // Notifications
        resetNotificationsVisible();
        resetNotificationsPageData();
        resetNotificationsAllData();
        resetUnreadNotificationsCount();

        // User
        resetUser();
        resetIsQuickTourActive();
        resetIsUserFirstLoggedIn();

        // Dashboard
        resetDateIntervalDataDashboard();

        // WS
        resetWSDashboardPlans();
        resetWSDashboardComingUp();
        resetWSDashboardChallenges();
        resetWSDashboardAccomplishments();
        resetWeeklyStatusActiveTab();
        resetAllWSItemsPaginated();
        resetDateIntervalDataWS();
        resetIsDateIntervalLoad();
        resetChartsData();
        resetGoalsStatusWSData();
        resetFilterSortByValue();
        resetFilterSortingMethod();
        resetFilterFilteredValues();
        resetIsWSByUserFiltered();

        // People
        resetAllUsersData();
        resetAllUsersPaginated();
        resetAddedOrRemovedPeople();
        resetEditableUserData();
        resetEditableTeamMember();
        resetIsActivityNeedToUpdate();
        resetAddedOrDeletedPostData();
        resetEditablePostData();
        resetSelectedPostsPage();
        resetSelectUsersData();

        // Teams
        resetTeams();
        resetTeamsPaginated();
        resetTeamsAndPeopleActiveKey();
        resetEditableTeam();
        resetEditedTeamData();
        resetIsSelectedTeam();
        resetAreTeamsAndPeopleFiltered();

        // Goals
        resetGoals();
        resetGoalStatus();
        resetEditableGoalData();
        resetEditedGoalData();
        resetGoalsByStatuses();
        resetIsGoalsFiltered();
        resetSpecificGoalShortData();
        resetSpecificGoalsData();
        resetAddedOrRemovedKeyResult();
        resetGoalStoreTimeLineData();
        resetGoalCommentShortData();
        resetGoalCommentPaginatedData();
        resetTotalComments();
        resetAttachedFilesTotal();

        // Reviews
        resetReviewListPaginated();
        resetReviewUpcomingListPaginated();
        resetReceivedReviewListPaginated();
        resetSubmittedReviewsData();
        resetUserReviewsData();
        resetUserReviewers();
        resetReviewsActiveTabKey();
        resetDateIntervalDataReviews();
        resetEntryReviewsDateInterval();
        resetIsFilteredMy();
        resetIsFilteredReceived();
        resetReviewsTotalCount();
        resetReviewsActiveColumn();

        // Surveys
        resetSurveyPaginatedData();
        resetEditableSurveyData();
        resetSurveyStatuses();
        resetReceivedTeamsData();
        resetReceivedUsersData();
        resetSurveyIsFiltered();
        resetChosenSurveyFilter();
        resetSurveyPreviewStatus();
        resetIsSurveyAddedOrganization();
        resetIsAnonymousUser();
        resetSurveyActiveTab();
        resetDateIntervalSurvey();
        resetENPSSurveyData();
        resetENPSCurrentSurveyData();
        resetGetENPSCompletionStatuses();
        resetENPSDateInterval();
        resetENPSPeriodTimelineShow();

        // Check-ins
        resetMeetingData();
        resetEditableCheckInData();
        resetEditableCheckIn();
        resetEditedCheckInData();
        resetMeetingsFiltered();
        resetCanMemberAddCheckin();
        resetCheckinItemAssigneesList();
        resetDateIntervalCheckIn();

        // Form Templates
        resetFormTemplatesDataPaginated();
        resetEditableFormTemplate();

        // Audit Trail
        resetAuditTrailFiltered();
        resetAuditTrailFilters();

        // Settings
        resetReviewPeriodSettings();
        resetSettingsModulesData();
        resetReviewGeneralSettings();
        resetReviewPeriodTimelineShow();
        resetCurrentlyActiveSettings();
        resetCurrentlyActiveIndex();
        resetCurrentlyActiveModule();
        resetSpecificReviewUser();
        resetUserCustomFields();
        resetReviewCheckedPeriod();
        resetSelectedBillingMenuItem();
        resetCategoriesState();
        resetActionsState();
        resetGoogleCalendarConnectedState();
        resetOutlookCalendarConnectedState();
        resetCustomUnitsData();
        resetTeamsActionState();
        resetWebhookActiveTab();

        // Feeds
        resetFeedsData();
        resetFeedsDataCount();
        resetFeedsHighlightData();

        // Pulse Point
        resetPulsePointActiveTab();
        resetPulsePointDateInterval();
    };

    return reset;
};

export default useResetRecoil;
