import request from "./utils/request";

export const tagging = {
    getTagsGoalsKRs: (id, name = '') => {
        return request('GET', `goals/tagging/comments/${id}?tagging=@&name=${name}`);
    },
    getCheckins: (id, name = '') => {
        return request('GET', `checkin/tagging/comments/${id}?tagging=@&name=${name}&is_private=0`);
    },
    getPosts: (id = '', name = '') => {
        return request('GET', `posts/tagging?tagging=@&name=${name}&is_private=0${id && `&id=${id}`}`);
    },
    getWeeklyStatusComments: (id, name = '') => {
        return request('GET', `weekly-status/tagging/comments/${id}?tagging=@&name=${name}`);
    },
    getPulsePointUsers: (id = '', name = '') => {
        return request('GET', `pulse_point/tagging/feedback?tagging=@&name=${name}`);
    },
    getSurveysUsers: (id = '', name = '') => {
        return request('GET', `survey/tagging/chats/${id}?tagging=@&name=${name}`);
    },
};
