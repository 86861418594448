import React from "react";
import PropTypes from 'prop-types';
import "./style/loading_spinner.scss";

/**
 * @param {{ size: 'large' | 'default' | 'small' }} props
 * @returns {JSX.Element}
*/
const LoadingSpinner = ({ size }) => {
    return (
        <div className="loader_container">
            <span className={`loader loader_${size !== 'default' ? size : 'default'}`}></span>
        </div>
    );
}
LoadingSpinner.defaultProps = {
    size: 'default',
}
LoadingSpinner.propTypes = {
    size: PropTypes.string,
}
export default LoadingSpinner;