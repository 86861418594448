import {useEffect} from "react";
import {useRecoilValue} from "recoil";
import moment from "moment";

// Utils
import {selectMe} from "store/selectors";
import {user} from "models/user";


const useTimezone = () => {
    const me = useRecoilValue(selectMe);

    useEffect(() => {
        if (!me.id) {
            return;
        }

        const userTimezone = moment().format('Z');

        if (me.timezone !== userTimezone) {
            if (!localStorage.getItem('auth_token')) {
                return;
            }

            user.updateUserTimezone({
                timezone: userTimezone,
            });
        }
    }, [me]);
};

export default useTimezone;
