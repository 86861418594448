// Goals States
export const onTrack = 'onTrack';
export const atRisk = 'atRisk';
export const offTrack = 'offTrack';
export const exceeded = 'exceeded';
export const completed = 'completed';
export const progressed = 'progressed';

// Chart Colors
export const accomplishmentsColor = '#218430';
export const challengesColor = '#ED3B5D';
export const plansColor = '#3E4BF4';
export const happinessColor = '#FF9D2B';
export const goalIconColor = '#341199';

// Chart names
export const chartLinks = {
    Plans: '#plan',
    Accomplishments: '#accomplishment',
    Challenges: '#challenge',
};