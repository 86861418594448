export const isUserAdmin = (role) => {
    return role === 1;
}

export const isUserSuperAdmin = (role) => {
    return role === 777;
}

export const isUserSeniorManager = (role) => {
    return role === 8;
}

export const isUserManager = (role) => {
    return role === 2;
}

export const isUserTeamMember = (role) => {
    return role === 3;
}

export const checkUserRole = (role) => {
    const roles = {
        isSuperAdmin: false,
        isAdmin: false,
        isSeniorManager: false,
        isManager: false,
        isMember: false,
    };

    if (isUserSuperAdmin(role)) {
        roles.isSuperAdmin = true;
    } else if (isUserAdmin(role)) {
        roles.isAdmin = true;
    } else if (isUserSeniorManager(role)) {
        roles.isSeniorManager = true;
    } else if (isUserManager(role)) {
        roles.isManager = true;
    } else if (isUserTeamMember(role)) {
        roles.isMember = true;
    }

    return roles;
};
