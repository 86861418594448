import {useEffect, useState} from "react";
import {useRecoilState, useResetRecoilState} from "recoil";

// Utils
import {selectionBarData} from "store";


/**
 * @param {object[] | object} actions
*/
const useSelectionBar = (actions) => {
    const [selectionBar, setSelectionBar] = useRecoilState(selectionBarData);
    const resetSelectionBar = useResetRecoilState(selectionBarData);

    const [checkedList, SetCheckedList] = useState([]);

    const count = checkedList.length;

    const handleCheck = (id) => {
        SetCheckedList((prevState) => {
            if (prevState.includes(id)) {
                return prevState.filter((goal) => goal !== id);
            }

            return [...prevState, id];
        });
    };

    const resetSelectionBarCount = () => {
        setSelectionBar((prevState) => {
            return {
                ...prevState,
                count: 0,
            };
        });
    };

    const resetCheckedList = () => {
        SetCheckedList([]);
    };

    const initializeOnReset = () => {
        setSelectionBar((prevState) => {
            return {
                ...prevState,
                onReset: resetCheckedList,
            };
        });
    };

    const changeSelectionBarCount = (count) => {
        setSelectionBar((prevState) => {
            return {
                ...prevState,
                count,
            };
        });
    };

    const initializeSelectionBarActions = (newActions = actions) => {
        setSelectionBar((prevState) => {
            return {
                ...prevState,
                actions: Array.isArray(newActions) ? newActions : [newActions],
            };
        });
    };

    useEffect(() => {
        changeSelectionBarCount(count);
    }, [count]);

    useEffect(() => {
        initializeOnReset();
        initializeSelectionBarActions();
    }, [checkedList]);

    useEffect(() => {
        return () => {
            resetSelectionBarCount();
        };
    }, []);

    return {
        checkedList,
        SetCheckedList,
        handleCheck,
        resetCheckedList,
        selectionBar,
        resetSelectionBar,
        changeSelectionBarCount,
    };
};

export default useSelectionBar;
