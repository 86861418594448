import React, {createContext, useCallback, useContext} from 'react';
import { useMedia } from 'react-use';
import {
    desktopMediaString,
    deviceTypes,
    mobileMediaString,
    tabletMediaString
} from "../pages/CompanySettings/CompanySettings.config";

export const DeviceTypeContext = createContext({});

/**
 * @return {{isMobile: boolean, isTablet: boolean, isDesktop: boolean}}
*/
const useDeviceType = () => {
    return useContext(DeviceTypeContext);
};

export const DeviceTypeProvider = ({children}) => {
    const isTablet = useMedia(tabletMediaString);
    const isMobile = useMedia(mobileMediaString);
    const isDesktop = useMedia(desktopMediaString);

    const handleDeviceType = useCallback(() => {
        if(isDesktop) {
            return deviceTypes.desktop;
        } else if(isTablet) {
            return deviceTypes.tablet;
        } else {
            return deviceTypes.mobile;
        }
    }, [isDesktop, isTablet]);

    return (
        <DeviceTypeContext.Provider value={{deviceType: handleDeviceType(), isTablet, isMobile, isDesktop}}>
            {children}
        </DeviceTypeContext.Provider>
    );
};

export default useDeviceType;
