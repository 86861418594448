/**
 * @param {{isAdmin: boolean, isSeniorManager: boolean, isManager: boolean, isMember: boolean}} userRole
 * @return {{target: string, content: string, disableBeacon: boolean, spotlightPadding?: number}[]}
*/
const getQuickTourSteps = (userRole) => {
    const {isManager, isSeniorManager, isMember} = userRole;

    let quickTourSteps = [
        {
            target: '.user_profile',
            content: 'Edit your profile info and check personalized notifications from here.',
            disableBeacon: true,
        },
        {
            target: '.search_bar > div',
            content: 'Use global search for items or users in the system․',
            disableBeacon: true,

        },
        {
            target: '.dashboard_content .items_container',
            content: 'See an overview of users’ weekly activity.',
            disableBeacon: true,
        },
        {
            target: '.weekly_statistics',
            content: 'Get account-wide goal completion rates and weekly activity results filtered by time period.',
            disableBeacon: true,
        },
        {
            target: '.ws_todo',
            content: 'See the full list of goals, weekly items, check-ins, and surveys with a deadline in the next 7 days.',
            disableBeacon: true,
        },
        {
            target: '.goal_progress_container',
            content: 'Easily access goal and goal progress from your dashboard',
            disableBeacon: true,
        },
        {
            target: '.intercom-lightweight-app-launcher',
            content: 'Get in touch with the support team.',
            disableBeacon: true,
        },
        {
            target: '.menu_item_weekly_status',
            content: 'Manage weekly plans to change their status to either Accomplishments or Challenges.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_goals',
            content: 'Create goals on company, team and individual levels and manage their completion through KR. You can assign both teams and individual users to the goal.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_reviews',
            content: 'Give feedback to your colleagues here and gather important data on areas of improvement.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_check-ins',
            content: 'Bring company meetings to one place and keep main talking points and notes together. The system will notify check-in members of the upcoming events.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_surveys',
            content: 'Use surveys to create and distribute company wide questionnaires. Assignees can include both teams and individual users.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_teams_people',
            content: 'Store your user data in one place and group users into teams for easier management and usage on the platform.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_form_templates',
            content: 'Prepare form templates that can be used to create surveys and review forms. Duplicate form templates for easier usage.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_audit_trail',
            content: 'Individual user activity is gathered here. Filter specific users to see what actions they performed in their goal completion process.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
        {
            target: '.menu_item_settings',
            content: 'Customize company settings, manage modules and change pricing packages from here.',
            disableBeacon: true,
            spotlightPadding: -1,
        },
    ]

    if (isManager || isSeniorManager) {
        quickTourSteps = quickTourSteps.slice(0, 14);
    } else if (isMember) {
        quickTourSteps = quickTourSteps.slice(0, 12);
    }

    return quickTourSteps;
}

export default getQuickTourSteps;