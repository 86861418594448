import React, {useState} from "react"
import {RecoilRoot} from 'recoil';
import {AuthContext, DeviceTypeProvider} from "context";
import './style/app.scss';
import ErrorBoundary from "HOC/ErrorBoundary/ErrorBoundery";
import Dashboard from './components/Dashboard/Dashboard';

export default function App() {
    const [authTokens, setAuthTokens] = useState(localStorage.getItem("auth_token"));

    const setTokens = (data) => {
        localStorage.setItem("auth_token", data);
        setAuthTokens(data);
    };

    const removeTokens = () => {
        localStorage.removeItem('auth_token');
    };

    return (
        <ErrorBoundary>
            <RecoilRoot>
                <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens, removeAuthTokens: removeTokens}}>
                    <DeviceTypeProvider>
                        <div className="App">
                            <Dashboard />
                        </div>
                    </DeviceTypeProvider>
                </AuthContext.Provider>
            </RecoilRoot>
        </ErrorBoundary>
    );
}


