import {useEffect} from "react";
import {useRecoilValue} from "recoil";

// Utils
import {socket} from "../sharedComponents/Socket/socket";
import {selectMe} from "store/selectors";
import {useLiveStates} from "Hooks";

/**
 * @param {string} name
 * @param {Function} callback
 * @param {Array} dependencies
 * @param {{}} options
*/
const useSocket = (name, callback, dependencies = [], options = {}) => {
  const { checkMyself = true } = options;
  const me = useRecoilValue(selectMe);
  const liveState = useLiveStates({
    companyId: me?.id_company,
    checkMyself,
  });


  useEffect(() => {
    const handler = (result) => {
      if (result.company === liveState.current.companyId) {
        if (result.company === liveState.current.companyId && socket.id !== result.id) {
          callback(result);
        } else if (!liveState.current.checkMyself) {
          callback(result);
        }
      }
    };

    socket.on(name, handler);

    return () => {
      socket.off(name, handler);
    };
  }, dependencies);
};

export default useSocket;
