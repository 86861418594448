import {useRecoilValue, useRecoilState} from "recoil";

// Hooks
import usePersist from "./usePersist";

// Utils
import {purchasedModulesData, purchasedPricingPlan} from 'store';


const useModules = () => {
    const [modulesData, setModulesData] = useRecoilState(purchasedModulesData);
    const [pricingPlan, setPricingPlan] = useRecoilState(purchasedPricingPlan);

    usePersist('modules', modulesData);
    usePersist('pricing', pricingPlan);

    return {
        modulesData,
        setModulesData,
        pricingPlan,
        setPricingPlan
    };
};

export default useModules;
